<template>
  <div>
    <v-navigation-drawer
      :value="isDrawerOpen"
      app
      floating
      width="420"
      class="app-navigation-menu"
      :right="$vuetify.rtl"
      @input="val => $emit('update:is-drawer-open', val)"
    >
      <!-- Navigation Header -->
      <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img
            :src="require('@/assets/images/logos/main-logo.png')"
            max-height="60px"
            max-width="60px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <v-slide-x-transition>
            <h4 class="text--primary">LABOTTEGA</h4>
          </v-slide-x-transition>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list expand shaped class="vertical-nav-menu-items">
        <nav-menu-link title="DASHBOARD" :to="{ name: 'dashboard' }"
                       :icon="icons.mdiHomeOutline"></nav-menu-link>
        <nav-menu-link title="YOUR INFORMATION'S" :to="{ name: 'personal-information' }"
                       :icon="icons.mdiAccountTie"></nav-menu-link>
        <nav-menu-link title="LIST OF MEMO'S" :to="{ name: 'list-of-memos' }"
                       :icon="icons.mdiMenuOpen"></nav-menu-link>
        <nav-menu-link title="BRANCH DIRECTORY" :to="{ name: 'branch-directory' }"
                       :icon="icons.mdiHomeSearch"></nav-menu-link>
        <nav-menu-link v-if="bank_trial_and_balance" title="Bank Trial & Balance Report"
                       :to="{ name: 'bank-trial-and-balance-report' }"
                       :icon="icons.mdiBank"></nav-menu-link>
        <nav-menu-link v-if="send_memo_bulk" title="Send Memo" :icon="icons.mdiSendCircle"
                       :to="{ name: 'send-memo' }"></nav-menu-link>
        <nav-menu-link v-if="list_of_bank_depository" title="List of Bank Depository"
                       :icon="icons.mdiBadgeAccountHorizontalOutline"
                       :to="{ name: 'list-of-bank-depository' }"></nav-menu-link>
        <nav-menu-section-title
          v-if="file_interface"
          title=""
        ></nav-menu-section-title>
        <nav-menu-group v-if="board_monitoring" title="BOARD MONITORING"
                        :icon="icons.mdiHuman">
          <nav-menu-link class="ml-3" title="Monthly Expenditure Report"
                         :to="{ name: 'monthly-expenditure-report' }"></nav-menu-link>
          <nav-menu-link class="ml-3" v-if="view_voucher_monitoring_pdc"
                         title="PDC Not Good Monitoring"
                         :to="{ name: 'post-dated-cheque-monitoring' }"></nav-menu-link>
          <nav-menu-link class="ml-3" title="Bank Trial & Balance Report"
                         :to="{ name: 'bank-trial-and-balance-report' }"></nav-menu-link>
          <nav-menu-link class="ml-3" title="Allocation of Fund"
                         :to="{ name: 'allocation-of-fund' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-section-title
          v-if="file_interface"
          title=""
        ></nav-menu-section-title>
        <nav-menu-group v-if="file_interface" title="FILE INTERFACE" :icon="icons.mdiFile">
          <nav-menu-group class="ml-3" v-if="cheque_book" title="Cheque Book"
                          :icon="icons.mdiCheckbook">
            <nav-menu-link class="ml-3" v-if="cheque_book_voucher_supply" title="Voucher Supply"
                           :to="{ name: 'new-cheque-book-voucher-supply' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="cheque_book_accounting_supply"
                           title="Accounting Supply"
                           :to="{ name: 'new-cheque-book-accounting-supply' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="file_register" title="Register"
                          :icon="icons.mdiPencilOutline">
            <nav-menu-link class="ml-3" v-if="file_register_user" title="New User for System"
                           :to="{ name: 'new-user' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_auto_maintenance_details"
                           title="Auto Maintenance Details"
                           :to="{ name: 'new-auto-maintenance-details' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_company_inventory"
                           title="Company Inventory"
                           :to="{ name: 'new-company-inventory' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_affiliate" title="New Affiliate"
                           :to="{ name: 'new-affiliate' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_employee" title="New Employee"
                           :to="{ name: 'new-employee' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_bank" title="New Bank Depository"
                           :to="{ name: 'new-bank-depositories' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_branch" title="New Branch"
                           :to="{ name: 'new-branch' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_bills_info" title="New Bills Info"
                           :to="{ name: 'new-bills-info' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_particulars" title="New Particulars"
                           :to="{ name: 'new-particulars' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_position" title="New Position"
                           :to="{ name: 'new-position' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_register_position" title="New Company"
                           :to="{ name: 'new-company' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-link class="ml-3" v-if="file_request_inventory" title="Request Inventory"
                         :icon="icons.mdiCartVariant"
                         :to="{ name: 'new-request-inventory' }"></nav-menu-link>
          <nav-menu-group class="ml-3" v-if="file_create" title="Create" :icon="icons.mdiPenPlus">
            <nav-menu-group class="ml-3" v-if="file_create_loan" title="Regular Loan Application"
                            :icon="icons.mdiCashMultiple">
              <nav-menu-link class="ml-3" v-if="file_create_auto" title="Auto Loan"
                             :to="{ name: 'new-auto-loan' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="file_create_auto" title="Personal Loan"
                             :to="{ name: 'new-personal-loan' }"></nav-menu-link>
            </nav-menu-group>
            <nav-menu-group class="ml-3" v-if="file_request" title="Request"
                            :icon="icons.mdiFileCabinet">
              <nav-menu-link class="ml-3" v-if="file_request_regular" title="Regular"
                             :to="{ name: 'new-regular-request' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="file_request_pdc" title="PDC"
                             :to="{ name: 'new-pdc-request' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="file_request_ftv" title="FTV"
                             :to="{ name: 'new-ftv-request' }"></nav-menu-link>
            </nav-menu-group>
            <nav-menu-link class="ml-3" v-if="file_create_others" title="New Other Receivable"
                           :to="{ name: 'new-other-receivable' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="file_search" title="Search" :icon="icons.mdiSearchWeb">
            <nav-menu-link class="ml-3" v-if="file_search_employee" title="Employee"
                           :to="{ name: 'search-employee' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_search_employee_payables"
                           title="Employee Payables"
                           :to="{ name: 'search-employee-payables' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_search_vouchers" title="Voucher"
                           :to="{ name: 'search-voucher' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="file_search_inventory" title="Inventory"
                           :to="{ name: 'search-inventory' }"></nav-menu-link>
          </nav-menu-group>
        </nav-menu-group>

        <nav-menu-group v-if="view_interface" title="VIEW INTERFACE"
                        :icon="icons.mdiViewAgenda">
          <nav-menu-group class="ml-3" v-if="view_inventory_monitoring" title="Inventory Monitoring"
                          :icon="icons.mdiStorefront">
            <nav-menu-link class="ml-3" v-if="view_inventory_monitoring_building"
                           title="Building"
                           :to="{ name: 'inventory-monitoring-building' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_inventory_monitoring_land" title="Land"
                           :to="{ name: 'inventory-monitoring-land' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_inventory_monitoring_car"
                           title="Car"
                           :to="{ name: 'inventory-monitoring-car' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_inventory_monitoring_equipment"
                           title="Equipment"
                           :to="{ name: 'inventory-monitoring-equipment' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="view_billing_monitoring" title="Billing Monitoring"
                          :icon="icons.mdiMonitorDashboard">
            <nav-menu-link class="ml-3"
                           title="Lot Rental"
                           :to="{ name: 'lot-rental-monitoring' }"></nav-menu-link>
            <nav-menu-link class="ml-3"
                           title="Electric"
                           :to="{ name: 'bill-electric-monitoring' }"></nav-menu-link>
            <nav-menu-link class="ml-3" title="Water"
                           :to="{ name: 'bill-water-monitoring' }"></nav-menu-link>
            <nav-menu-link class="ml-3"
                           title="Office"
                           :to="{ name: 'bill-office-monitoring' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-link class="ml-3" v-if="view_auto_maintenance_monitoring"
                         title="Auto Maintenance Monitoring"
                         :to="{ name: 'auto-maintenance-monitoring' }"></nav-menu-link>

          <nav-menu-group class="ml-3" v-if="view_employee_directory" title="Employee Directory"
                          :icon="icons.mdiAccountGroupOutline">
            <nav-menu-link class="ml-3" v-if="view_employee_directory_pending"
                           title="Pending Employee"
                           :to="{ name: 'pending-employee' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_employee_directory_list" title="List of Employee"
                           :to="{ name: 'list-employee' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_employee_directory_list"
                           title="List of Maternity Employee"
                           :to="{ name: 'list-maternity-employee' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_employee_directory_pending_salary"
                           title="Pending Salary Increase Report"
                           :to="{ name: 'pending-salary-increase-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_employee_directory_salary"
                           title="Salary Increase Report"
                           :to="{ name: 'salary-increase-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_employee_directory_branch"
                           title="Change Branch/Position Report"
                           :to="{ name: 'change-branch-position-report' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="view_salary" title="Salaries & 13th MonthPay"
                          :icon="icons.mdiAccountCash">
            <nav-menu-link class="ml-3" v-if="view_salary_payroll"
                           title="Payroll"
                           :to="{ name: 'payroll-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_salary_payslip" title="Payroll Slip"
                           :to="{ name: 'payslip-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_salary_monthpa" title="13th Month Payroll"
                           :to="{ name: '13-month-payroll-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_salary_monthpayslip"
                           title="13th Month Payroll Slip"
                           :to="{ name: '13th-monthpayslip-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_salary_monthpamonitorng"
                           title="13th Month Monitoring"
                           :to="{ name: '13-month-monitoring' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_salary_monthpa" title="14th Month Payroll"
                           :to="{ name: '14-month-payroll-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_salary_monthpayslip"
                           title="14th Month Payroll Slip"
                           :to="{ name: '14th-monthpayslip-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="view_salary_monthpamonitorng"
                           title="14th Month Monitoring"
                           :to="{ name: '14-month-monitoring' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-link class="ml-3" v-if="view_bank_transaction"
                         title="Bank Trial & Balance Report"
                         :to="{ name: 'bank-trial-and-balance-report' }"></nav-menu-link>
          <nav-menu-link class="ml-3" v-if="view_bank_transaction" title="Bank Transaction History"
                         :icon="icons.mdiBank"
                         :to="{ name: 'bank-transaction-history' }"></nav-menu-link>
          <nav-menu-link class="ml-3" v-if="view_bank_transaction"
                         title="Remittances Transaction History"
                         :icon="icons.mdiBank"
                         :to="{ name: 'remittances-transaction-history' }"></nav-menu-link>

          <nav-menu-group class="ml-3" v-if="view_monitoring" title="Monitoring"
                          :icon="icons.mdiMonitor ">
            <nav-menu-group class="ml-3" v-if="view_employee_payables"
                            title="Employee Payables"
                            :icon="icons.mdiBankTransferOut ">
              <nav-menu-link class="ml-3" title="Coop Loan"
                             :to="{ name: 'company-receivable-coop-loan' }"></nav-menu-link>
              <nav-menu-link class="ml-3" title="Auto Loan"
                             :to="{ name: 'company-receivable-auto-loan' }"></nav-menu-link>
              <nav-menu-link class="ml-3" title="Cash Advance"
                             :to="{ name: 'company-receivable-cash-advance' }"></nav-menu-link>
              <nav-menu-link class="ml-3" title="Loan"
                             :to="{ name: 'company-receivable-loan' }"></nav-menu-link>
              <nav-menu-link class="ml-3"
                             title="Other Receivable"
                             :to="{ name: 'company-receivable-other-receivable' }"></nav-menu-link>
              <nav-menu-link class="ml-3"
                             title="Motor Finance"
                             :to="{ name: 'company-receivable-motor-finance' }"></nav-menu-link>
            </nav-menu-group>
            <nav-menu-group class="ml-3" v-if="view_bir" title="BIR Monitoring"
                            :icon="icons.mdiHomeGroup ">
              <nav-menu-link class="ml-3" v-if="view_bir_alpha" title="BIR Alpha List"
                             :to="{ name: 'bir_monitoring-alpha-list' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_bir_month" title="Monthly Gov. Benefits"
                             :to="{ name: 'bir_monitoring-monthly-gov-benefits' }"></nav-menu-link>
            </nav-menu-group>
            <nav-menu-group class="ml-3" v-if="view_cheque_book" title="Cheque Book Monitoring"
                            :icon="icons.mdiCheckbook ">
              <nav-menu-link class="ml-3" v-if="view_cheque_book_all" title="Over All"
                             :to="{ name: 'cheque-book-monitoring-over-all' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_cheque_book_supply" title="Supply"
                             :to="{ name: 'cheque-book-monitoring-supply' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_cheque_book_cancled" title="Canceled Cheque"
                             :to="{ name: 'cheque-book-monitoring-canceled' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_cheque_book_month" title="Monthly Used"
                             :to="{ name: 'cheque-book-monitoring-monthly-used' }"></nav-menu-link>
            </nav-menu-group>
            <nav-menu-group class="ml-3" v-if="view_company_receivables"
                            title="Company Receivable Monitoring"
                            :icon="icons.mdiCallReceived ">
              <nav-menu-link class="ml-3" v-if="view_company_receivables_auto" title="Auto Loan"
                             :to="{ name: 'company-receivable-auto-loan' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_company_receivables_ca" title="Cash Advance"
                             :to="{ name: 'company-receivable-cash-advance' }"></nav-menu-link>
              <nav-menu-link class="ml-3" title="Coop Loan"
                             :to="{ name: 'company-receivable-coop-loan' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_company_receivables_loan" title="Loan"
                             :to="{ name: 'company-receivable-loan' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_company_receivables_others"
                             title="Other Receivable"
                             :to="{ name: 'company-receivable-other-receivable' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_company_receivables_others"
                             title="Office Rental Sec Deposit"
                             :to="{ name: 'company-receivable-office-sec-receivable' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_company_receivables_motor"
                             title="Motor Finance"
                             :to="{ name: 'company-receivable-motor-finance' }"></nav-menu-link>
            </nav-menu-group>
            <nav-menu-group class="ml-3" v-if="view_voucher_monitoring" title="Voucher Monitoring"
                            :icon="icons.mdiTicketPercent ">
              <nav-menu-link class="ml-3" v-if="view_voucher_monitoring_cv"
                             title="Cash Voucher Monitoring"
                             :to="{ name: 'cash-voucher-monitoring' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_voucher_monitoring_pdc" title="PDC Monitoring"
                             :to="{ name: 'post-dated-cheque-monitoring' }"></nav-menu-link>
              <nav-menu-link class="ml-3" v-if="view_voucher_monitoring_ftv"
                             title="Fund Transfer Voucher Monitoring"
                             :to="{ name: 'fund-transfer-voucher-monitoring' }"></nav-menu-link>
            </nav-menu-group>
          </nav-menu-group>
        </nav-menu-group>

        <nav-menu-group v-if="reports_interface" title="REPORTS" :icon="icons.mdiChartTree">
          <nav-menu-group class="ml-3" v-if="reports_company_receivable"
                          title="Company Receivable Remittances"
                          :icon="icons.mdiCallReceived ">
            <nav-menu-link class="ml-3" v-if="reports_company_receivable_auto" title="Auto Loan"
                           :to="{ name: 'company-receivable-auto-loan-remittances' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_company_receivable_ca" title="Cash Advance"
                           :to="{ name: 'company-receivable-cash-advance-remittances' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_company_receivable_loan" title="Loan"
                           :to="{ name: 'company-receivable-loan-remittances' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_company_receivable_others"
                           title="Other Receivable"
                           :to="{ name: 'company-receivable-other-receivable-remittances' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_company_receivable_others"
                           title="Office Rental Sec Deposit"
                           :to="{ name: 'company-receivable-office-sec-deposit-remittances' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_company_receivable_motor"
                           title="Motor Finance"
                           :to="{ name: 'company-receivable-motor-finance-remittances' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="reports_expenditure" title="Expenditure"
                          :icon="icons.mdiCashRemove">
            <nav-menu-link class="ml-3" v-if="reports_expenditure_annual"
                           title="Annual Expenditure Report"
                           :to="{ name: 'annual-expenditure-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_expenditure_annual_monitoring"
                           title="Annual Expenditure Monitoring Report"
                           :to="{ name: 'annual-expenditure-monitoring-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_expenditure_month"
                           title="Monthly Expenditure Report"
                           :to="{ name: 'monthly-expenditure-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_expenditure_month_pdc"
                           title="Monthly PDC Expenditure Report"
                           :to="{ name: 'monthly-pdc-expenditure-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_expenditure_month_auto_deb"
                           title="Monthly AUTO DEB. Expenditure Report"
                           :to="{ name: 'monthly-auto-deb-expenditure-report' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="reports_revenue" title="Revenue"
                          :icon="icons.mdiCashPlus">
            <nav-menu-link class="ml-3" v-if="reports_revenue_annual"
                           title="Annual Gross Revenue Report"
                           :to="{ name: 'annual-gross-revenue-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_revenue_annual_trust"
                           title="Annual Trust Fund Report"
                           :to="{ name: 'annual-trust-fund-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_revenue_monthly"
                           title="Monthly Revenue Report"
                           :to="{ name: 'monthly-income-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_revenue_monthly"
                           title="Monthly Bank Interest Report"
                           :to="{ name: 'monthly-bank-interest-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_revenue_daily_deposited_bank"
                           title="Daily Bank Deposit Report"
                           :to="{ name: 'daily-deposit-bank-report' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-link class="ml-3" v-if="reports_pre_liquidation" title="Pre Liquidation Report"
                         :icon="icons.mdiCashRegister"
                         :to="{ name: 'pre-liquidation-report' }"></nav-menu-link>
          <nav-menu-group class="ml-3" v-if="reports_vouchers" title="Vouchers Report"
                          :icon="icons.mdiTicketPercent">
            <nav-menu-link class="ml-3" v-if="reports_vouchers_for_approval"
                           title="List of Vouchers for Approval"
                           :to="{ name: 'list-for-approval-voucher-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_vouchers_cv" title="Cash Voucher"
                           :to="{ name: 'cash-voucher-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_vouchers_ckv" title="Cheque Voucher"
                           :to="{ name: 'cheque-voucher-report' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="reports_vouchers_ftv" title="Fund Transfer Voucher"
                           :to="{ name: 'fund-transfer-voucher-report' }"></nav-menu-link>
          </nav-menu-group>
        </nav-menu-group>

        <!--        <nav-menu-section-title-->
        <!--          v-if="input_interface"-->
        <!--          title="INPUT INTERFACE"-->
        <!--        ></nav-menu-section-title>-->
        <nav-menu-group v-if="input_interface" title="INPUT INTERFACE" :icon="icons.mdiImport ">
          <nav-menu-group class="ml-3" v-if="input_business_permit" title="Business Permit"
                          :icon="icons.mdiGoogleMyBusiness ">
            <nav-menu-link class="ml-3" v-if="input_business_permit_released" title="Release Fund"
                           :to="{ name: 'bussiness-permit-released-fund' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_business_permit_liquidation" title="Liquidation"
                           :to="{ name: 'bussiness-permit-liquidation' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_business_permit_return" title="Return Fund"
                           :to="{ name: 'bussiness-permit-return-fund' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-link class="ml-3" v-if="input_cash_flow" title="Remittances Cash Flow"
                         :icon="icons.mdiCash100"
                         :to="{ name: 'remittances-cash-flow' }"></nav-menu-link>
          <nav-menu-link class="ml-3" v-if="input_cash_flow" title="Cash Flow"
                         :icon="icons.mdiCash100"
                         :to="{ name: 'cash-flow' }"></nav-menu-link>
          <nav-menu-group class="ml-3" v-if="input_sec_deposit" title="Security Deposit"
                          :icon="icons.mdiSecurity ">
            <nav-menu-link class="ml-3" v-if="input_sec_deposit_office" title="Office/Chapel Rental"
                           :to="{ name: 'security-deposit-office-rental' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="input_payment_receivables" title="Payment Receivables"
                          :icon="icons.mdiContactlessPayment ">
            <nav-menu-link class="ml-3" v-if="input_payment_receivables_auto" title="Auto Loan"
                           :to="{ name: 'company-receivable-auto-loan-payment' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_payment_receivables_ca" title="Cash Advance"
                           :to="{ name: 'company-receivable-cash-advance-payment' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_payment_receivables_loan" title="Loan"
                           :to="{ name: 'company-receivable-loan-payment' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_payment_receivables_others"
                           title="Other Receivable"
                           :to="{ name: 'company-receivable-other-receivable-payment' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_payment_receivables_motors"
                           title="Motor Finance"
                           :to="{ name: 'company-receivable-motor-finance-payment' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_payment_receivables_motors"
                           title="Office Rental Sec Deposit"
                           :to="{ name: 'company-receivable-office-sec-payment' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_payment_receivables_bod" title="BOD Charge"
                           :to="{ name: 'company-receivable-bod-charge-payment' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-link class="ml-3" v-if="input_deposit_slip" title="Receivable - Deposit Slip"
                         :icon="icons.mdiCashRefund"
                         :to="{ name: 'deposit-slip-receivable' }"></nav-menu-link>
          <nav-menu-group class="ml-3" v-if="input_salary" title="Salaries & 13th MonthPay"
                          :icon="icons.mdiCurrencyUsd">
            <nav-menu-link class="ml-3" v-if="input_salary_deductions" title="Salary Billing"
                           :to="{ name: 'salary-deductions' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_salary_data"
                           title="Salaries and Wages Working Days"
                           :to="{ name: 'salaries-and-wages-working-days' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_salary_data" title="Salaries and Wages"
                           :to="{ name: 'salaries-and-wages' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_salary_data" title="Incentives Payroll"
                           :to="{ name: 'chapel-payroll' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_salary_monthpa" title="13th MonthPay"
                           :to="{ name: 'thirten-pay' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_salary_monthpa" title="14th MonthPay"
                           :to="{ name: 'fourteen-pay' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="input_monthly_liquidation" title="Monthly Liquidation"
                          :icon="icons.mdiCalculatorVariant">
            <nav-menu-link class="ml-3" v-if="input_monthly_liquidation_reg" title="Regular Expense"
                           :to="{ name: 'ml-regular-expense' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_monthly_liquidation_ca" title="Cash Advance"
                           :to="{ name: 'ml-cash-advance-expense' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_monthly_liquidation_ca"
                           title="(CKV)Cash Advance"
                           :to="{ name: 'ml-cash-advance-expense-ckv' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_department_fund_monitoring"
                           title="RF Monitoring"
                           :to="{ name: 'department-fund-monitoring' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_department_fund_monitoring"
                           title="Sales Drive Monitoring"
                           :to="{ name: 'sales-drive-monitoring' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_monthly_liquidation_department"
                           title="Department Fund Liquidation"
                           :to="{ name: 'ml-department' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_monthly_liquidation_damayan_rf"
                           title="RF Liquidation"
                           :to="{ name: 'ml-damayan-revolving-fund' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="input_vouchers" title="Vouchers"
                          :icon="icons.mdiTicketPercent">
            <nav-menu-link class="ml-3" v-if="input_vouchers_cv" title="Cash Voucher"
                           :to="{ name: 'create-cash-voucher' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_vouchers_ckv" title="Cheque Voucher"
                           :to="{ name: 'create-cheque-voucher' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_vouchers_ftv" title="Fund Transfer Voucher"
                           :to="{ name: 'create-fund-transfer-voucher' }"></nav-menu-link>
          </nav-menu-group>
          <nav-menu-group class="ml-3" v-if="input_maintenance" title="Maintenance"
                          :icon="icons.mdiTools">
            <nav-menu-link class="ml-3" v-if="input_liquidations_maintenance"
                           title="Liquidation Maintenance"
                           :to="{ name: 'liquidations-maintenance' }"></nav-menu-link>
            <nav-menu-link class="ml-3" v-if="input_voucher_maintenance" title="Voucher Maintenance"
                           :to="{ name: 'voucher-maintenance' }"></nav-menu-link>
          </nav-menu-group>
        </nav-menu-group>

        <!--        <nav-menu-section-title-->
        <!--          v-if="load_interface"-->
        <!--          title="LOAD INTERFACE"-->
        <!--        ></nav-menu-section-title>-->
        <nav-menu-group v-if="load_interface" title="LOAD INTERFACE"
                        :icon="icons.mdiDownloadMultiple ">
          <nav-menu-group v-if="load_interface_stocks" class="ml-3" title="Stocks"
                          :icon="icons.mdiFileUpload ">
            <nav-menu-link v-if="load_interface_stocks_equipment" class="ml-3"
                           title="Equipment Inventory Stock"
                           :icon="icons.mdiPlusBox"
                           :to="{ name: 'load-equipment-inventory-stocks' }"></nav-menu-link>
            <nav-menu-link v-if="load_interface_stocks_car" class="ml-3" title="Car Inventory Stock"
                           :icon="icons.mdiCar2Plus"
                           :to="{ name: 'load-car-inventory-stocks' }"></nav-menu-link>
          </nav-menu-group>
        </nav-menu-group>

      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiAccountTie,
    mdiBadgeAccountHorizontalOutline,
    mdiHuman,
    mdiMenuOpen,
    mdiViewListOutline,
    mdiHomeSearch,
    mdiGoogleStreetView,
    mdiHomeOutline,
    mdiPencilOutline,
    mdiAccountGroupOutline,
    mdiCash,
    mdiAccountCash,
    mdiPenPlus,
    mdiFileCabinet,
    mdiCashMultiple,
    mdiSearchWeb,
    mdiCheckbook,
    mdiBank,
    mdiMonitor,
    mdiGoogleMyBusiness,
    mdiHomeGroup,
    mdiCallReceived,
    mdiTicketPercent,
    mdiCashRemove,
    mdiCashPlus,
    mdiCashRegister,
    mdiCash100,
    mdiSecurity,
    mdiContactlessPayment,
    mdiCashRefund,
    mdiCurrencyUsd,
    mdiCalculatorVariant,
    mdiTools,
    mdiFile,
    mdiViewAgenda,
    mdiChartTree,
    mdiImport,
    mdiDownloadMultiple,
    mdiPlusBox,
    mdiCar2Plus,
    mdiFileUpload,
    mdiCartVariant,
    mdiStorefront,
    mdiBankTransferOut,
    mdiSendCircle,
    mdiMonitorDashboard,
    mdiCodepen,
  } from '@mdi/js'
  import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
  import NavMenuGroup from './components/NavMenuGroup.vue'
  import NavMenuLink from './components/NavMenuLink.vue'
  import {mapActions, mapGetters} from 'vuex'

  export default {
    components: {
      NavMenuSectionTitle,
      NavMenuGroup,
      NavMenuLink,
    },
    computed: {
      ...mapGetters('authentication', ['position', 'is_can_evaluate']),
      ...mapGetters('authentication', [
        'employee_id',
        'file_interface',
        'cheque_book',
        'cheque_book_voucher_supply',
        'cheque_book_accounting_supply',
        'file_register',
        'file_create',
        'file_create_loan',
        'file_request',
        'file_request_list_for_approval',
        'file_search',
        'view_interface',
        'view_employee_directory',
        'view_salary',
        'view_monitoring',
        'view_bir',
        'view_cheque_book',
        'view_company_receivables',
        'view_voucher_monitoring',
        'reports_interface',
        'reports_bod_annual',
        'reports_company_receivable',
        'reports_expenditure',
        'reports_revenue',
        'reports_vouchers',
        'input_interface',
        'input_bod',
        'input_business_permit',
        'input_sec_deposit',
        'input_payment_receivables',
        'input_salary',
        'input_monthly_liquidation',
        'input_vouchers',
        'file_register_employee',
        'file_register_auto_maintenance_details',
        'file_register_company_inventory',
        'file_register_bank',
        'file_register_branch',
        'file_register_bills_info',
        'file_register_position',
        'file_register_particulars',
        'file_register_affiliate',
        'file_create_auto',
        'file_create_personal',
        'file_request_regular',
        'file_request_pdc',
        'file_request_ftv',
        'file_create_motor',
        'file_create_others',
        'file_create_minimum_wages',
        'file_search_employee',
        'file_search_employee_payables',
        'file_search_vouchers',
        'view_employee_directory_pending',
        'view_employee_directory_list',
        'view_employee_directory_salary',
        'view_employee_directory_pending_salary',
        'view_employee_directory_branch',
        'view_salary_payroll',
        'view_salary_payslip',
        'view_salary_monthpa',
        'view_salary_monthpayslip',
        'view_salary_monthpamonitorng',
        'view_bank_transaction',
        'view_monitoring_business',
        'view_bir_alpha',
        'view_bir_month',
        'view_cheque_book_all',
        'view_cheque_book_supply',
        'view_cheque_book_cancled',
        'view_cheque_book_month',
        'view_company_receivables_auto',
        'view_company_receivables_ca',
        'view_company_receivables_loan',
        'view_company_receivables_others',
        'view_company_receivables_motor',
        'view_company_receivables_bod',
        'view_voucher_monitoring_cv',
        'view_voucher_monitoring_pdc',
        'bank_trial_and_balance',
        'view_voucher_monitoring_ftv',
        'reports_bod_annual_dam',
        'reports_bod_annual_chap',
        'reports_company_receivable_auto',
        'reports_company_receivable_ca',
        'reports_company_receivable_loan',
        'reports_company_receivable_others',
        'reports_company_receivable_motor',
        'reports_company_receivable_bod',
        'reports_expenditure_annual',
        'reports_expenditure_annual_monitoring',
        'reports_expenditure_month',
        'reports_expenditure_month_pdc',
        'reports_expenditure_month_auto_deb',
        'reports_revenue_annual',
        'reports_revenue_annual_trust',
        'reports_revenue_monthly',
        'reports_revenue_monthly_dam',
        'reports_pre_liquidation',
        'reports_vouchers_for_approval',
        'reports_vouchers_cv',
        'reports_vouchers_ckv',
        'reports_vouchers_ftv',
        'input_bod_month',
        'input_business_permit_released',
        'input_business_permit_liquidation',
        'input_business_permit_return',
        'input_cash_flow',
        'input_sec_deposit_office',
        'input_payment_receivables_auto',
        'input_payment_receivables_ca',
        'input_payment_receivables_loan',
        'input_payment_receivables_others',
        'input_payment_receivables_motors',
        'input_payment_receivables_bod',
        'input_deposit_slip',
        'input_salary_deductions',
        'input_salary_data',
        'input_salary_monthpa',
        'input_monthly_liquidation_reg',
        'input_monthly_liquidation_ca',
        'input_vouchers_cv',
        'input_vouchers_ckv',
        'input_vouchers_ftv',
        'input_voucher_maintenance',
        'load_interface',
        'load_interface_stocks',
        'load_interface_stocks_equipment',
        'load_interface_stocks_car',
        'file_request_inventory',
        'view_inventory_monitoring',
        'view_inventory_monitoring_building',
        'view_inventory_monitoring_land',
        'view_inventory_monitoring_car',
        'view_inventory_monitoring_equipment',
        'input_monthly_liquidation_department',
        'file_register_user',
        'file_register_tie_up',
        'view_auto_maintenance_monitoring',
        'file_search_inventory',
        'view_employee_payables',
        'view_billing_monitoring',
        'view_amd_monitoring',
        'view_as',
        'view_department_fund_monitoring',
        'reports_revenue_daily_deposited_bank',
        'input_monthly_liquidation_damayan_rf',
        'input_monthly_liquidation_chapel_rf',
        'board_monitoring',
        'input_department_fund_monitoring',
        'input_maintenance',
        'input_liquidations_maintenance',
        'view_damayan_incentives_monitoring',
        'send_memo_bulk',
        'list_of_bank_depository',
      ]),
    },
    props: {
      isDrawerOpen: {
        type: Boolean,
        default: null,
      },
    },
    setup() {
      return {
        icons: {
          mdiAccountTie,
          mdiBadgeAccountHorizontalOutline,
          mdiHuman,
          mdiHomeOutline,
          mdiMenuOpen,
          mdiViewListOutline,
          mdiHomeSearch,
          mdiGoogleStreetView,
          mdiPencilOutline,
          mdiAccountGroupOutline,
          mdiCash,
          mdiAccountCash,
          mdiPenPlus,
          mdiFileCabinet,
          mdiCashMultiple,
          mdiSearchWeb,
          mdiCheckbook,
          mdiBank,
          mdiSendCircle,
          mdiMonitor,
          mdiGoogleMyBusiness,
          mdiHomeGroup,
          mdiCallReceived,
          mdiTicketPercent,
          mdiCashRemove,
          mdiCashPlus,
          mdiCashRegister,
          mdiCash100,
          mdiSecurity,
          mdiContactlessPayment,
          mdiCashRefund,
          mdiCurrencyUsd,
          mdiCalculatorVariant,
          mdiTools,
          mdiFile,
          mdiViewAgenda,
          mdiChartTree,
          mdiImport,
          mdiDownloadMultiple,
          mdiPlusBox,
          mdiCar2Plus,
          mdiFileUpload,
          mdiCartVariant,
          mdiStorefront,
          mdiBankTransferOut,
          mdiMonitorDashboard,
          mdiCodepen,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .app-title {
    font-size: 1.25rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  // ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
  .app-logo {
    transition: all 0.18s ease-in-out;

    .v-navigation-drawer--mini-variant & {
      transform: translateX(-4px);
    }
  }

  @include theme(app-navigation-menu) using($material) {
    background-color: map-deep-get($material, 'background');
  }

  .app-navigation-menu {
    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }

  // You can remove below style
  // Upgrade Banner
  .app-navigation-menu {
    .upgrade-banner {
      position: absolute;
      bottom: 13px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
